import { defineStore } from "pinia";
import axios from "axios";
import { ref } from "vue";


export const useStockTakeStore = defineStore("stockTakeStore", {
  id: "stockTakeStore",
  state: () => ({
    stockTakeSheets: [],
    products: [],
    dataToSend: {},
    selectedCamera: "",
    stockTakeHistory: [],
    logs: []
  }),
  getters: {
    hasSavedData: (state) =>
      state.stockTakeSheets.length > 0 || state.products.length > 0,
    apiUrl: () => process.env.VUE_APP_ADMIN_URL,
  },
  actions: {
    // Fetch stock take sheets for a given user
    async fetchStockTakeSheets(userId) {
      console.log("Fetching stock take sheets for user ID:", userId);

      this.logActivity("Fetching stock take sheets", { userId });

      //Remove existing stock take sheets and products
      localStorage.removeItem("stockTakeSheets");
      localStorage.removeItem("stockTakeHistory");
      localStorage.removeItem("products");

      try {
        const response = await axios.get(
          this.apiUrl + "api/v1/get/stockTakeSheets/" + userId
        );
        this.stockTakeSheets = response.data.sheets;

        console.log(this.stockTakeSheets);

        // Log sheet names and product counts
        const sheetsLog = this.stockTakeSheets.map(sheet => ({
          name: sheet.file_name
        }));
        this.logActivity("Fetched stock take sheets", { sheets: sheetsLog });

        // Optionally, cache the data locally
        // localStorage.setItem("stockTakeSheets", JSON.stringify(this.stockTakeSheets));

        this.products = [];
        for (const sheet of this.stockTakeSheets) {
          await this.fetchProducts(sheet.id);
        }
      } catch (error) {
        console.error("Error fetching stock take sheets:", error);
        this.logActivity("Error fetching stock take sheets", { error: error.message });
        // Handle errors appropriately
      }
      console.log("Done fetching stock take sheets and products");

      localStorage.setItem("stockTakeSheets", JSON.stringify(this.stockTakeSheets));
      localStorage.setItem("products", JSON.stringify(this.products));
      localStorage.setItem("stockTakeHistory", JSON.stringify(this.stockTakeHistory));
    },

    // Fetch products for a given sheet
    async fetchStockTakeSheet(sheetId) {
      console.log("Fetching stock take sheet for sheet ID:", sheetId);
      this.logActivity("Fetching stock take sheet", { sheetId });
    
      try {
        // Find the sheet in existing data
        const sheetIndex = this.stockTakeSheets.findIndex(sheet => sheet.id === sheetId);
        if (sheetIndex === -1) {
          console.error("Sheet not found:", sheetId);
          return;
        }
    
        // Fetch updated sheet data
        const response = await axios.get(this.apiUrl + `api/v1/get/stockTakeSheet/${sheetId}`);
        const updatedSheet = response.data.sheet;
    
        // Replace the existing sheet data with the updated one
        this.stockTakeSheets[sheetIndex] = updatedSheet;
    
        // Fetch products for the updated sheet
        await this.fetchProducts(sheetId);
    
        // Update local storage only for this sheet
        localStorage.setItem("stockTakeSheets", JSON.stringify(this.stockTakeSheets));
        localStorage.setItem("products", JSON.stringify(this.products));
    
        console.log("Updated stock take sheet:", updatedSheet);
        this.logActivity("Updated stock take sheet", { sheet: updatedSheet });
    
      } catch (error) {
        console.error("Error fetching stock take sheet:", error);
        this.logActivity("Error fetching stock take sheet", { error: error.message });
      }
    },

    
    async fetchProducts(sheetId) {
      try {
        console.log(sheetId);
        const response = await axios.get(
          this.apiUrl + "api/v1/get/stockTakeProducts/" + sheetId
        );
        const fetchedProducts = response.data.products;
        this.products = this.products.concat(fetchedProducts);
        // console.log("Fetched products for sheet:", sheetId, fetchedProducts);
        this.logActivity("Fetched stock take products", { productCount: fetchedProducts.length });
      } catch (error) {
        console.error("Error fetching products:", error);
        // Handle errors appropriately
      }
    },

    //This method synchronizes the stockTakeStore back to the database
    async syncData(selectedSheets) {
      this.logActivity("Uploading sheets", { sheetCount: selectedSheets.length });

      try {
        console.log("start sync");

        this.dataToSend = {
          stockTakeSheets: selectedSheets || this.stockTakeSheets,
          products: this.products,
          activityLogs: this.logs,
        };

        const response = await axios.post(
          this.apiUrl + "api/v1/update/stockTakeData",
          this.dataToSend,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        console.log(response.data);

        localStorage.removeItem("stockTakeSheets");
        localStorage.removeItem("products");
        localStorage.removeItem("stockTakeHistory");
        localStorage.removeItem("activityLogs");

        this.stockTakeHistory = [];

        this.logActivity("Sheets uploaded successfully", { response });

        const userId = localStorage.getItem("user");
        this.fetchStockTakeSheets(userId);

        this.logActivity("Sheets fetched successfully", { response });

        return response.data;
      } catch (error) {
        this.logActivity("Error uploading sheets", { error: error.message });
        console.log(error);
      }
    },

    async refreshData() {

      const userId = ref(null);
      userId.value = localStorage.getItem("user");

      this.logActivity("Refreshing sheets for user ", { userId: userId.value });
      console.log("refreshing sheets for user ", userId.value);

      try {
        console.log("start refresh");

      //   // Make API call to get data
        const response = await axios.get(
          this.apiUrl + "api/v1/get/stockTakeSheets/" + userId.value
        );

        localStorage.removeItem("stockTakeSheets");
        localStorage.removeItem("products");
        localStorage.removeItem("stockTakeHistory");

        this.stockTakeHistory = [];

        // Update stockTakeStore
        this.stockTakeSheets = response.data.sheets;

        const sheetsLog = this.stockTakeSheets.map(sheet => ({
          name: sheet.file_name
        }));
        this.logActivity("Fetched stock take sheets", { sheets: sheetsLog });

        this.products = [];
        for (const sheet of this.stockTakeSheets) {
          await this.fetchProducts(sheet.id);
        }

        this.logActivity("Sheets refreshed successfully", { response });

      } catch (error) {
        this.logActivity("Error refreshing sheets", { error: error.message });
        console.log(error);
      }

      localStorage.setItem("stockTakeSheets", JSON.stringify(this.stockTakeSheets));
      localStorage.setItem("products", JSON.stringify(this.products));
      localStorage.setItem("stockTakeHistory", JSON.stringify(this.stockTakeHistory));
    },


    hydrateCountHistory() {
      const stockTakeHistory = localStorage.getItem("stockTakeHistory");
      if (stockTakeHistory) {
        this.stockTakeHistory = JSON.parse(stockTakeHistory);
      }
    },

    hydrateLogs() {
      const savedLogs = localStorage.getItem("activityLogs");
      if (savedLogs) {
        this.logs = JSON.parse(savedLogs);
      } else {
        this.logs = [];
      }
    },

    hydrateAllData() {
      const stockTakeSheets = localStorage.getItem("stockTakeSheets");
      const products = localStorage.getItem("products");
      const stockTakeHistory = localStorage.getItem("stockTakeHistory");
      const savedLogs = localStorage.getItem("activityLogs");

      if (stockTakeSheets) this.stockTakeSheets = JSON.parse(stockTakeSheets);
      if (products) this.products = JSON.parse(products);
      if (stockTakeHistory) this.stockTakeHistory = JSON.parse(stockTakeHistory);
      if (savedLogs) this.logs = JSON.parse(savedLogs);
    },


    updateStockTakeProduct(productId, newStatus, newQty) {
      const productIndex = this.products.findIndex(
        (product) => product.id === productId
      );

      if (productIndex !== -1) {
        this.products[productIndex].status = newStatus;

        this.addCount(productId, newQty);

        if (this.products[productIndex].qty === "Nothing") {
          this.products[productIndex].qty = Number(newQty);
        } else {
          this.products[productIndex].qty =
            Number(this.products[productIndex].qty) + Number(newQty);
        }

        this.logActivity("User counted product", {
          productId,
          productBarcode: this.products[productIndex].product_barcode,
          productName: this.products[productIndex].product_description,
          newQty,
          totalQty: this.products[productIndex].qty,
        });

      } else {
        console.warn("Stock take product not found:", productId);
      }
    },

    addCount(productId, count) {
      const product = this.stockTakeHistory.find(p => p.productId === productId);
      if (product) {
        product.counts.push({ count, timestamp: new Date().toISOString() });
      } else {
        // If the product doesn't exist, create a new one
        this.stockTakeHistory.push({
          productId,
          productDescription: 'New Product',
          counts: [{ count, timestamp: new Date().toISOString() }],
        });
      }
    },

    resetCounts(productId) {
      const product = this.stockTakeHistory.find(p => p.productId === productId);
      if (product) {
        // Filter out the product with the specified productId
        this.stockTakeHistory = this.stockTakeHistory.filter(product => product.productId !== productId);
      }
    },

    // Add a new action to retrieve stock take history for a product
    getProductHistory(productId) {

      const productHistory = this.stockTakeHistory.find(
        (history) => history.productId === productId
      );

      return productHistory ? productHistory.counts : []; // Return an empty object if no history found
    },

    updateStockTakeSheetStatus(sheetId, newStatus) {
      const sheetIndex = this.stockTakeSheets.findIndex(
        (sheet) => sheet.id === Number(sheetId)
      );

      if (sheetIndex !== -1) {
        this.stockTakeSheets[sheetIndex].status = newStatus;
      } else {
        console.warn("Stock take sheet not found:", sheetId);
      }
    },

    setSelectedCamera(camera) {
      this.selectedCamera = camera;
    },

    async logActivity(action, details = {}) {
      const timestamp = new Date().toLocaleString("en-ZA", {
        timeZone: "Africa/Johannesburg",
      });
      const logEntry = { action, details, timestamp };
      this.logs.push(logEntry);
      localStorage.setItem("activityLogs", JSON.stringify(this.logs));

      // // Prepare the log for the API
      // const logPayload = {
      //   log_category: this.getLogCategory(action),
      //   log: JSON.stringify({ action, details, timestamp }),
      //   user_id: localStorage.getItem("user"),
      // };
      //
      // // Send to Laravel API
      // try {
      //   await axios.post(`${this.apiUrl}api/sheet-activity-logs`, logPayload, {
      //     headers: { "Content-Type": "application/json" },
      //   });
      // } catch (error) {
      //   console.error("Failed to send log to the server", error.message);
      // }
    },

    getLogCategory(action) {
      if (action.includes("Wifi")) return "Wifi Status";
      if (action.includes("Fetched")) return "Fetched Data";
      if (action.includes("Pushed")) return "Pushed Data";
      return "User Activity";
    },


  },
});
