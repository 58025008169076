<script setup>
import { ref, computed, onMounted, onUnmounted, watch } from "vue";
import { useStockTakeStore } from "@/stores/stockTakeStore";
import { useModalStore } from "@/stores/modalStore";
import { ArrowPathIcon } from "@heroicons/vue/24/outline";

const modalStore = useModalStore();
const stockTakeStore = useStockTakeStore();
const sheets = computed(() => stockTakeStore.stockTakeSheets);
const selectedSheet = ref(0);
const isOnline = ref(navigator.onLine);
let intervalId;

const parsedDetails = ref(null);

// Function to parse filename
const parseFileName = (fileName) => {
	if (!fileName) return null;

	// Remove .csv extension if present
	const cleanedFileName = fileName.replace(".csv", "");

	// Split the filename by ";"
	const parts = cleanedFileName.split(";");

	// Ensure we have at least 11 parts (to avoid errors)
	if (parts.length < 11) return null;

	return {
		companyId: parts[0],
		companyName: parts[1],
		userId: parts[2],
		userName: parts[3],
		branchId: parts[4],
		branchName: parts[5],
		costCentreId: parts[6],
		costCentreName: parts[7],
		stockTakeNumber: parts[8],
		sheetNumber: parts[9],
		dueDate: parts[10],
	};
};

// Computed property to check if due date is in the past
const isDueDatePast = computed(() => {
	if (!parsedDetails.value || !parsedDetails.value.dueDate) return false;

	const dueDateStr = parsedDetails.value.dueDate;
	const dueDate = new Date(
		dueDateStr.slice(0, 4), // Year
		dueDateStr.slice(4, 6) - 1, // Month (0-based)
		dueDateStr.slice(6, 8), // Day
		dueDateStr.slice(9, 11), // Hour
		dueDateStr.slice(11, 13) // Minute
	);

	return dueDate < new Date();
});

// Format due date
const formattedDueDate = computed(() => {
	if (!parsedDetails.value || !parsedDetails.value.dueDate) return "";

	let dueDateStr = parsedDetails.value.dueDate.replace(".csv", "").trim();

	// Ensure the date is in YYYYMMDD HHMMSS format
	if (!/^\d{8} \d{6}$/.test(dueDateStr)) {
		console.warn("Invalid due date format:", dueDateStr);
		return "Invalid Date";
	}

	// Extract year, month, and day
	const year = parseInt(dueDateStr.slice(0, 4), 10);
	const month = parseInt(dueDateStr.slice(4, 6), 10) - 1; // JavaScript months are 0-based
	const day = parseInt(dueDateStr.slice(6, 8), 10);

	// Create Date object
	const dueDate = new Date(year, month, day);

	// Check if the date is valid
	if (isNaN(dueDate.getTime())) {
		console.warn("Invalid due date detected:", dueDateStr);
		return "Invalid Date";
	}

	return dueDate.toLocaleDateString("en-GB", {
		day: "2-digit",
		month: "long",
		year: "numeric",
	}); // Example output: "21 May 2025"
});

// Connectivity Check
const checkConnectivity = async () => {
	try {
		const response = await fetch("https://jsonplaceholder.typicode.com/posts", {
			method: "HEAD",
		});
		isOnline.value = response.ok;
	} catch (error) {
		isOnline.value = false;
	}
};

// Log Activity Function
const logActivity = (action, details) => {
	stockTakeStore.logActivity(action, details);
};

const hasScannedProducts = computed(() => {
	if (!selectedSheet.value) return false;

	// Get products for the selected sheet
	const sheetProducts = stockTakeStore.products.filter(
		(product) => product.stock_take_sheet_id === selectedSheet.value
	);

	// Check if any product in stockTakeHistory matches a product in this sheet
	return sheetProducts.some((product) =>
		stockTakeStore.stockTakeHistory.some((history) => history.productId === product.id)
	);
});

// Watch Selected Sheet Changes
watch(selectedSheet, (newValue) => {
	if (newValue !== 0) {
		const sheet = sheets.value.find((sheet) => sheet.id === newValue);
		if (sheet) {
			logActivity("Sheet selected for counting", {
				sheetName: sheet.original_filename,
				sheetId: sheet.id,
			});
			parsedDetails.value = parseFileName(sheet.original_filename);
		}

		console.log(sheet);
	}
});

// Log View Products Button Click
const logViewProducts = () => {
	const sheet = sheets.value.find((sheet) => sheet.id === selectedSheet.value);
	if (sheet) {
		logActivity("View Products button pressed", {
			sheetName: sheet.original_filename,
			sheetId: sheet.id,
		});
	}
};

// const refreshData = () => {
//   console.log("Refreshing data...");
// };

onMounted(() => {
	intervalId = setInterval(checkConnectivity, 30000);
	checkConnectivity();

	window.addEventListener("online", () => {
		isOnline.value = true;
	});
	window.addEventListener("offline", () => {
		isOnline.value = false;
	});
});

onUnmounted(() => {
	clearInterval(intervalId);
	window.removeEventListener("online", () => {
		isOnline.value = true;
	});
	window.removeEventListener("offline", () => {
		isOnline.value = false;
	});
});
</script>

<template>
	<div class="p-6 w-full">
		<div class="bg-white rounded-lg shadow-lg max-w-2xl mx-auto">
			<div class="px-6 py-4 flex flex-col text-left">
				<div class="flex justify-between items-center mb-2">
					<h2 class="font-bold text-xl mb-2">Stock Take Sheets</h2>
					<button
            class="flex justify-right rounded-md bg-blue-900 hover:bg-blue-900/80 px-4 py-2 text-white"
						@click="modalStore.openRefreshDataModal()"
					>
						<arrow-path-icon class="h-6 w-6" />
					</button>
				</div>

				<div
					v-if="stockTakeStore.stockTakeSheets.length > 0"
					class="flex flex-col text-left"
				>
					<select
						class="block w-full mb-4 text-lg bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 mr-2 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
						v-model="selectedSheet"
					>
						<option value="0" selected disabled>Select Stock Take Sheet</option>
						<option v-for="sheet in sheets" :key="sheet.id" :value="sheet.id">
							{{ sheet.file_name }}
						</option>
					</select>

					<!-- Summary Section -->
					<div
						v-if="parsedDetails"
						:class="{ 'bg-red-300': isDueDatePast, 'bg-green-300': !isDueDatePast }"
						class="p-4 mb-4 border rounded-lg"
					>
						<div class="flex justify-between items-center">
							<h2 class="text-lg font-bold mb-2">Summary</h2>
							<span
								v-if="hasScannedProducts"
								class="bg-yellow-500 text-white px-2 py-1 rounded"
							>
								Has Scanned Items
							</span>
						</div>

						<div class="grid grid-cols-2 gap-4">
							<span class="font-bold">Company ID:</span>
							<span>{{ parsedDetails.companyId }}</span>
							<span class="font-bold">Company Name:</span>
							<span>{{ parsedDetails.companyName }}</span>
							<span class="font-bold">User ID:</span>
							<span>{{ parsedDetails.userId }}</span>
							<span class="font-bold">User Name:</span>
							<span>{{ parsedDetails.userName }}</span>
							<span class="font-bold">Branch ID:</span>
							<span>{{ parsedDetails.branchId }}</span>
							<span class="font-bold">Branch Name:</span>
							<span>{{ parsedDetails.branchName }}</span>
							<span class="font-bold">Cost Centre ID:</span>
							<span>{{ parsedDetails.costCentreId }}</span>
							<span class="font-bold">Cost Centre Name/Department:</span>
							<span>{{ parsedDetails.costCentreName }}</span>
							<span class="font-bold">Stock Take Number / Count Number:</span>
							<span>{{ parsedDetails.stockTakeNumber }}</span>
							<span class="font-bold">Sheet Number:</span>
							<span>{{ parsedDetails.sheetNumber }}</span>
							<span class="font-bold">Due Date:</span> <span>{{ formattedDueDate }}</span>
						</div>
					</div>

					<router-link
						:to="{ name: 'Products', params: { sheetId: selectedSheet } }"
						v-if="selectedSheet !== 0"
						@click="logViewProducts"
						class="w-56 bg-blue-900/80 hover:bg-blue-900 text-white text-center font-bold py-2 px-4 text-xl rounded mb-2"
						>View Products</router-link
					>
				</div>

				<div
					v-if="stockTakeStore.stockTakeSheets.length === 0"
					class="block self-center w-full md:w-3/4 bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 mr-2 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
				>
					No Stock Take Sheets Found
				</div>
			</div>
		</div>
	</div>
</template>
