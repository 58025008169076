import { createApp, watch, computed, nextTick } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import "./style.css";
import { createPinia } from "pinia";
import { useStockTakeStore } from "@/stores/stockTakeStore";

const pinia = createPinia();
const app = createApp(App);

app.use(router).use(pinia).mount("#app");

// Ensure Pinia store is initialized **after** mounting
nextTick(async () => {
  const stockTakeStore = useStockTakeStore(); // Hydrate the store with product counts from localStorage data
  await stockTakeStore.hydrateAllData(); // Ensures initial data is loaded

  // checkForOldData(stockTakeStore);
});

window.addEventListener("online", () => {
    const stockTakeStore = useStockTakeStore();
    stockTakeStore.logActivity("Network connected");
});

window.addEventListener("offline", () => {
    const stockTakeStore = useStockTakeStore();
    stockTakeStore.logActivity("Network disconnected");
});

const userId = new URLSearchParams(window.location.search).get('user_id');
if (userId) {
    const stockTakeStore = useStockTakeStore();
    stockTakeStore.logActivity("User authenticated and redirected", { userId });
}



watch(
  pinia.state,
  (state) => {
    // console.log("state: ", state.stockTakeStore);
    
    const stockTakeSheetsArray = computed(() => state.stockTakeStore.stockTakeSheets);
    // console.log("stockTakeSheetsArray: ", stockTakeSheetsArray);
    const productsArray = computed(() => state.stockTakeStore.products);
    // console.log("productsArray: ", productsArray);

    localStorage.setItem("stockTakeSheets", JSON.stringify(stockTakeSheetsArray.value));
    localStorage.setItem("products", JSON.stringify(productsArray.value));
    localStorage.setItem("selectedCamera", (state.stockTakeStore.selectedCamera));
    localStorage.setItem("stockTakeHistory", JSON.stringify(state.stockTakeStore.stockTakeHistory));
  },
  {
    deep: true,
  }
);

// Function to check for outdated data
// async function checkForOldData(stockTakeStore) {
//   console.log("Checking for outdated data...");
//   const localStockTakeSheets = JSON.parse(localStorage.getItem("stockTakeSheets")) || [];
//   const piniaStockTakeSheets = stockTakeStore.stockTakeSheets || [];

//   console.log("localStockTakeSheets: ", localStockTakeSheets);
//   console.log("piniaStockTakeSheets: ", piniaStockTakeSheets);

//   if (localStockTakeSheets.length === 0 || piniaStockTakeSheets.length === 0) return;

//   // Extract stock take details for comparison
//   function parseFilename(sheet) {
//       if (!sheet || !sheet.original_filename) return null;
//       const parts = sheet.original_filename.split(";");
//       return {
//           stockTakeNumber: parseInt(parts[8], 10) || 0,
//           sheetNumber: parseInt(parts[9], 10) || 0,
//           dueDate: new Date(parts[10].replace(" ", "T")) // Convert date properly
//       };
//   }

//   let isOutdated = false;

//   for (const localSheet of localStockTakeSheets) {
//       const storedData = parseFilename(localSheet);
//       const latestSheet = piniaStockTakeSheets.find(sheet => sheet.id === localSheet.id);

//       if (latestSheet) {
//           const latestData = parseFilename(latestSheet);

//           if (
//               storedData.stockTakeNumber < latestData.stockTakeNumber ||
//               storedData.sheetNumber < latestData.sheetNumber ||
//               storedData.dueDate < new Date()
//           ) {
//               isOutdated = true;
//               break;
//           }
//       }
//   }

//   if (isOutdated) {
//       console.log("Detected outdated stock take data. Refreshing...");
//       await stockTakeStore.fetchStockTakeSheets(stockTakeStore.userId);
//   }
// }