import { defineStore } from 'pinia';

export const useModalStore = defineStore('modalStore', {
  state: () => ({
    showStocktakePostModal: false,  // State to control stock take sync confirmation modal visibility
    showRefreshDataModal: false,  // State to control refresh data confirmation modal visibility
  }),
  actions: {
    openStocktakePostModal() {
      this.showStocktakePostModal = true;
    },
    closeStocktakePostModal() {
      this.showStocktakePostModal = false;
    },

    openRefreshDataModal() {
      this.showRefreshDataModal = true;
    },
    closeRefreshDataModal() {
      this.showRefreshDataModal = false;
    },
  }
});