<script setup>
import {
	TransitionRoot,
	TransitionChild,
	Dialog,
	DialogPanel,
	DialogTitle,
} from "@headlessui/vue";

import { ExclamationTriangleIcon } from "@heroicons/vue/24/outline";
import { useStockTakeStore } from "@/stores/stockTakeStore";
import router from "@/router";
import { useModalStore } from "@/stores/modalStore";

const modalStore = useModalStore();
const stockTakeStore = useStockTakeStore();

const refreshData = () => {
	document.getElementById("loader-wheel").classList.remove("hidden");

	try {

		stockTakeStore.refreshData();

		// modalStore.showRefreshDataModal = false;


		document.getElementById("loader-wheel").classList.add("hidden");

		showSuccessToast();

		router.push({ name: "Sheets" });
	} catch (error) {
		console.error("Error syncing data:", error);
		document.getElementById("loader-wheel").classList.add("hidden");
	} finally {
		document.getElementById("loader-wheel").classList.add("hidden");
    modalStore.closeRefreshDataModal
	}

	closeModal();
};

function closeModal() {
	modalStore.showRefreshDataModal = false;
}

const showSuccessToast = () => {
	document.getElementById("refreshToast").classList.remove("hidden");
	// Hide the toast after 5 seconds (5000ms)
	// you can set a shorter/longer time by replacing "5000" with another number
	setTimeout(function () {
		document.getElementById("refreshToast").classList.add("hidden");
	}, 5000);
};


</script>


<template>
	<TransitionRoot appear :show="modalStore.showRefreshDataModal" as="template">
		<Dialog as="div" @close="modalStore.closeRefreshDataModal()" class="relative z-10">
			<TransitionChild
				as="template"
				enter="duration-300 ease-out"
				enter-from="opacity-0"
				enter-to="opacity-100"
				leave="duration-200 ease-in"
				leave-from="opacity-100"
				leave-to="opacity-0"
			>
				<div class="fixed inset-0 bg-black/25" />
			</TransitionChild>

			<div class="fixed inset-0 overflow-y-auto">
				<div class="flex min-h-full items-center justify-center p-4 text-center">
					<TransitionChild
						as="template"
						enter="duration-300 ease-out"
						enter-from="opacity-0 scale-95"
						enter-to="opacity-100 scale-100"
						leave="duration-200 ease-in"
						leave-from="opacity-100 scale-100"
						leave-to="opacity-0 scale-95"
					>
						<DialogPanel
							class="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all"
						>
							<DialogTitle
								as="h3"
								class="text-lg font-medium leading-6 text-gray-900 items-center justify-center flex flex-col"
							>
                <ExclamationTriangleIcon class="h-16 w-16 text-red-700" />
								<span>Are you sure you want to continue?</span>
							</DialogTitle>

							<hr />

							<div class="flex items-center mb-2 mt-2">
                <span>This action will remove all scanning history that has not been synced to the system so far. Please confirm that you want to continue.</span>
							</div>

							<hr />

							<div class="mt-4 flex justify-between">
								<button
									type="button"
									class="inline-flex justify-center rounded-md border border-transparent text-base font-medium px-4 py-2 bg-green-500 hover:bg-green-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-green-500"
									@click="refreshData()"
								>
									Confirm
								</button>

								<button
									type="button"
									class="inline-flex justify-center rounded-md border border-transparent text-base font-medium px-4 py-2 bg-red-500 hover:bg-red-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-red-500"
									@click="modalStore.closeRefreshDataModal()"
								>
									Cancel
								</button>
							</div>
						</DialogPanel>
					</TransitionChild>
				</div>
			</div>
		</Dialog>
	</TransitionRoot>
</template>


